import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from "./shared/shared.module";

import { HomeModule } from "./features/home/home.module";
import { AuthGuardService } from "./services/auth-guard.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService, HTTPStatus } from './services/interceptor.service';
import { NavBarComponent } from './features/nav-bar/nav-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { LoginComponent } from './features/authentication/login/login.component';
import { ForgotPasswordComponent } from './features/authentication/forgot-password/forgot-password.component';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { OnboardingComponent } from './features/authentication/onboarding/onboarding.component';


import { RetailerDstechSuccessComponent } from './features/authentication/retailer-dstech-success/retailer-dstech-success.component';
import {EditCustomerComponent} from './features/edit-customer/edit-customer.component';



@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    LoginComponent,
    ForgotPasswordComponent,
    OnboardingComponent,

    
    RetailerDstechSuccessComponent,
    EditCustomerComponent
    
    

  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    SharedModule,
    HttpClientModule,
    HomeModule,
    FormsModule,
    ReactiveFormsModule,
    
    
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    AuthGuardService,
    
    InterceptorService,
    HTTPStatus,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
