import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-retailer-dstech-success',
  templateUrl: './retailer-dstech-success.component.html',
  styleUrls: ['./retailer-dstech-success.component.scss']
})
export class RetailerDstechSuccessComponent implements OnInit {
  public Phrase1:string ='';
  public Phrase2:string ='';
  public Phrase3 = '';

  constructor(private router:Router) {
    if (this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation().extras.state) {
      this.Phrase1 = this.router.getCurrentNavigation().extras.state.Phrase1;
      this.Phrase2 = this.router.getCurrentNavigation().extras.state.Phrase2;
      this.Phrase3 = this.router.getCurrentNavigation().extras.state.Phrase3;
    }
  }

  ngOnInit(): void {
  }
  redirectLogin():void {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);

  }


}
