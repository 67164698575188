
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from "./features/home/home.component";
import { LoginComponent } from "./features/authentication/login/login.component";
import { ForgotPasswordComponent } from "./features/authentication/forgot-password/forgot-password.component";
import { AuthGuardService } from "./services/auth-guard.service";

import { OnboardingComponent } from './features/authentication/onboarding/onboarding.component';

import { RetailerDstechSuccessComponent } from './features/authentication/retailer-dstech-success/retailer-dstech-success.component';
import {EditCustomerComponent} from './features/edit-customer/edit-customer.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: '', component: HomeComponent, canActivate: [AuthGuardService] },
  
  
  { path: 'retailersignsuccess', component: RetailerDstechSuccessComponent},
  {
    path: 'iqos-kit',
    loadChildren: () => import('./features/nuova-iqos/primo-step/primo-step.module').then(m => m.PrimoStepModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'lista-trials',
    loadChildren: () => import('./features/trials/trials.module').then(m => m.TrialsModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'profilo',
    loadChildren: () => import('./features/profilo/profilo.module').then(m => m.ProfiloModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'resi-effettuati',
    loadChildren: () => import('./features/resi-effettuati/resi-effettuati.module').then(m => m.ResiEffettuatiModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'supporto',
    loadChildren: () => import('./features/supporto/supporto.module').then(m => m.SupportoModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'purchases',
    loadChildren: () => import('./features/customer/customer/customer.module').then(m => m.CustomerModule)
    
  },
  {
    path: 'success',
    loadChildren: () => import('./features/nuova-iqos/success/success.module').then(m => m.SuccessModule),

  },
  {
    path: 'motivazione-reso',
    loadChildren: () => import('./features/motivazione-reso/motivazione-reso.module').then(m => m.MotivazioneResoModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'modifica-anagrafica',
    component: EditCustomerComponent,
    canLoad: [AuthGuardService]
  },
  {
    path: 'onboarding', component: OnboardingComponent
  },
  {
    path: 'onboardingRefresh', component: OnboardingComponent
  },
  { path: '**', redirectTo: '' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
