import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

const PURCHASE_URL = `${environment.endpointUri}/purchases`;

@Injectable({
  providedIn: 'root'
})


export class ContractService {

  constructor(private http: HttpClient) {

  }

  getSigningURL(purchaseid: number): Observable<any> {
    let url: string = PURCHASE_URL + '/';
    url += purchaseid.toString() + '/purchaseContractUrl';
    return this.http.get(url, {});
  }

  getPaymentURL(purchaseid: number): Observable<any> {
    let url: string = PURCHASE_URL + '/';
    url += purchaseid.toString() + '/customerPaymentUrl';
    return this.http.get(url);

  }

  getReturnUrl(purchaseid: number, receipt: string): Observable<any> {
    let url: string = PURCHASE_URL + '/';
    url += purchaseid.toString() + '/returnContract';

    return this.http.patch(url, {ReceiptNumber: receipt});
  }

  postRecieptPhoto(purchaseid: number, photo): Observable<any> {
    let url: string = PURCHASE_URL + '/';
    url += purchaseid.toString() + '/uploadBill';
    return this.http.post(url, photo);

  }

  getReceiptPhoto(purchaseId: number): Observable<any> {
    let url: string = PURCHASE_URL + '/';
    url += purchaseId.toString() + '/bill';
    return this.http.get(url, {responseType: 'arraybuffer'});
  }

}
