<div class="modal-header">
  <h4 class="modal-title">{{titleBox}}</h4>
  <button type="button" class="close x-btn" aria-label="Close" (click)="close(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{message}}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark close-btn" (click)="close(false)">Annulla</button>
  <button type="button" class="btn btn-outline-dark close-btn" (click)="close(true)">Accetta</button>
</div>
