import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ListItemsComponent } from '../features/items/list-items/list-items.component';
import {ReceiptComponent} from './receipt/receipt.component';
import { YousignComponent } from './yousign/yousign.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';


// Da usare per directives, pipes e components usati da tutti i moduli. NON usare per i services.
@NgModule({
  declarations: [
    ListItemsComponent,
    YousignComponent,
    ReceiptComponent,
    ConfirmationModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    ListItemsComponent,
    YousignComponent,
    ReceiptComponent
  ]
})
export class SharedModule { }
