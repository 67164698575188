<div class="yousign-content">
    <iframe [src]="safeURL">
  </iframe>

  <div class="proceed-btn">
      <div class="row justify-content-center">  
          <div class="col-10 col-md-10 col-lg-5 col-sm-10">
          <button  (click)="OnProceed()"  class="btn form-control button-primary btn-block">Procedi</button>
          </div>  
      </div>
  </div>
</div>