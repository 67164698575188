<div id="background-image">
  <div class="container-title">
    <img src="../../../../assets/logo-iqos-white.svg">
    <h2>Benvenuto!</h2>
  </div>

  <div class="container-form ">
    <form class="col-lg-6 offset-lg-3 " [formGroup]="loginForm" (ngSubmit)="login()">
      <div class="row flex-column flex-center">
        <div class="form-group ">
          <input class="form-control input-text-username" type="text" formControlName="username"
                 (click)="isErrorMessage = false" placeholder="Username">
        </div>
        <div class="form-group ">
          <input class="form-control input-text-password" type="password" formControlName="password"
                 (click)="isErrorMessage = false" placeholder="Password">
        </div>
        <div class="form-group ">
          <button class="form-control button-primary" type="submit" value="Accedi" [disabled]="loginForm.invalid">Accedi</button>
        </div>
        <div *ngIf="this.isErrorMessage">
          <p class="text-danger">L'email o la password che hai inserito sono errati</p>
        </div>
      </div>
    </form>
  </div>
  <div class="container-footer">
    <footer class="m-t-10">
      <p class="m-0">Hai dimenticato la password? <span class="link-modal btn-link" (click)="open(content)">Clicca qui</span></p>
      <p>Hai bisogno di aiuto?<span style="color: #d5a867"> Contattaci 06 97625546</span></p>
    </footer>
  </div>
</div>


<ng-template #content let-modal>
  <div class="container-modal">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Richiesta cambio password</h4>
      <img src="assets/icons/x.svg" class="flex-column close-modal" (click)="modal.dismiss('Cross Click')"></div>
    <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
      <div class="modal-body">
          <div class="form-group">
            <div class="input-group">
              <input id="email" class="form-control" formControlName="email" placeholder="Email*" name="email">
            </div>
      </div>
      <div class="modal-footer px-0">
        <button type="submit" class="btn btn-outline-dark" [disabled]="resetPasswordForm.invalid">Reset password</button>
      </div>
      </div>
    </form>
  </div>
</ng-template>
