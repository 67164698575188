<div *ngIf="this.isPathProfilo()">
  <div class="navbar-mobile">
    <nav class="navbar navbar-expand-lg nav-mobile-profile">
      <a href="" class="navbar-brand button-back">
        <img src="assets/icons/arrow-left.svg">
      </a>
      <p class="title-profile">Profilo</p>
    </nav>
  </div>
  <div class="navbar-desktop">
    <nav class="navbar fixed-top">
      <a class="navbar-brand ml-4" href="">
        <img src="../assets/logo-iqos-blu.svg">
      </a>
      <div class="row">
        <div class="avatar-badge-nav">
          <img src="assets/home/user-avatar.svg" class="avatar-user">
        </div>
        <p class="avatar-badge-nav-name">{{getProfileName()}}</p>
      </div>
    </nav>
    <nav class="navbar navbar-expand-lg navbar-dark nav-mobile-profile"></nav>
  </div>
</div>


<div *ngIf="this.isPathNeeded()">
  <div class="navbar-mobile">
    <nav class="navbar navbar-expand-lg nav-mobile">
      <a class="navbar-brand button-back" role="button" (click)="redirect()">
        <img src="assets/icons/arrow-left.svg">
      </a>
      <p class="title">{{titleNavBar()}}</p>
    </nav>
  </div>

  <div class="navbar-desktop">
    <nav class="navbar nav-desktop">
      <a class="navbar-brand ml-4" role="button" (click)="redirect()">
        <img src="../assets/logo-iqos-blu.svg">
      </a>
      <div class="row">
        <div class="avatar-badge-nav">
          <img src="assets/home/user-avatar.svg" class="avatar-user">
        </div>
        <p class="avatar-badge-nav-name">{{getProfileName()}}</p>
      </div>
    </nav>
  </div>

</div>
