import {Injectable} from '@angular/core';
import {HateoasService} from './hateoas.service';
import {environment} from '../../environments/environment';
import {GetList} from '../interfaces/generic-links';
import {TrialGetList} from '../models/trial';
import {Answer} from '../models/answer';
import {Observable} from 'rxjs';
const TRIAL_URL = `${environment.endpointUri}/purchases/search/findByState?state=`;
const ACTIVATE_TRIAL_URL = `${environment.endpointUri}/purchases/`;
// const SEARCH_TRIAL_URL = `${environment.endpointUri}/purchases/search/findByKeyword?state=WAIT&keyword=100&projection=Trial`;
const SEARCH_TRIAL_URL = `${environment.endpointUri}/purchases/search/findByKeyword?state=`;
const RETURN_TRIAL_URL = `${environment.endpointUri}/purchases/`;
const BILL_URL = `${environment.endpointUri}/document/nodeElementContent`;

@Injectable({
  providedIn: 'root'
})
export class TrialService extends HateoasService{

  getTrials(state: string){
    return this.http.get<GetList<TrialGetList>>(TRIAL_URL + state + '&projection=Trial');
  }
  activateTrial(idPurchase: string){
    return this.createObject(ACTIVATE_TRIAL_URL + idPurchase + '/startTrial', {});
  }
  cancelTrial(idPurchase: string){
    return this.patchObject(RETURN_TRIAL_URL + idPurchase, {state: 'FAILED'});
  }
  searchTrial(state: string , keyword: string){
    return this.http.get<GetList<TrialGetList>>(SEARCH_TRIAL_URL + state + '&keyword=' + keyword + '&projection=Trial');
  }
  returnTrial(idTrial: number, answers: Answer[]){
    return this.createObject(RETURN_TRIAL_URL + idTrial + '/return', {answers: answers});
  }
  resendEmail(idTrial: number, isAfterTrial: boolean){
    return this.createObject(ACTIVATE_TRIAL_URL + idTrial + '/resendStartTrial?afterTrial=' + isAfterTrial, {});
    // return this.createObject(ACTIVATE_TRIAL_URL + idTrial + '/resendStartTrial', {});
  }
  uploadBill(idTrial, scontrino) {
    return this.createObject(RETURN_TRIAL_URL + idTrial + '/uploadBill', scontrino);
  }
  downloadBill(bill: string){
    return this.http.get(BILL_URL + "?key=" + bill, { observe: 'response', responseType: 'blob'});
  }

  payInCash(idTrial: number): Observable<any>{
    var url:string = RETURN_TRIAL_URL + idTrial.toString() +"/payInCash";
    return this.http.patch(url, {});
  }

  modifyCustomer(purchaseId:number,nome: string, cognome: string, codiceFiscale: string, email: string, telefono: number,dataNascita:string): Observable<any> {

    var url:string = RETURN_TRIAL_URL + purchaseId.toString() + "/customer";
    return this.patchObject(url, {nome:nome,cognome:cognome,codiceFiscale:codiceFiscale,email:email,phone:telefono,dataNascita:dataNascita});
  }
  getCustomer(purchaseId:number)
  {
    var url:string = RETURN_TRIAL_URL + purchaseId.toString() + "/customer";
    return this.http.get(url);
  }

 
}
