import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Hateoas } from '../models/hateoas';
import {Contract} from '../models/contract'
import { GetList } from '../interfaces/generic-links';
import { HttpClient } from '@angular/common/http';
const RETAILER_URL = `${environment.endpointUri}/retailers`;

@Injectable({
  providedIn: 'root'
})
export class RetailerService {

  constructor(private http:HttpClient) { }
  public getRetailerStatus(username:string) {
    var url:string = RETAILER_URL + "/retailerStatus?retailer=" + username;
    return this.http.get(url);
  }
  public getRetailerContract(username:string) {
    var url:string = RETAILER_URL + "/retailerContract?retailer=" +username;
    return this.http.patch(url,{});
  }
}
