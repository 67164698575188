<div class="container mt-5">
    <h3 class="text-center">Modifica Informazioni</h3>
    <form [formGroup]="customerForm" (ngSubmit)="submitModify()">

      <div class="row mt-5 justify-content-center">
        <div class="col-10 col-md-10 col-lg-7 col-sm-10">
          <div class="form-group">
            <input
              type="text"
              formControlName="nome"
              placeholder="Nome *"
              class="form-control input-placeholder"/>
          </div>
          <div
            class="form-group mb-4 textOfAge flex-center span4"
            *ngIf="customerForm.controls.nome.errors && customerForm.controls.nome.dirty"
            style="margin-top: -1.3rem">
            Inserire un campo valido
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-10 col-md-10 col-lg-7 col-sm-10 mt-2">
          <div class="form-group">
            <input
              type="text"
              formControlName="cognome"
              placeholder="Cognome *"
              class="form-control input-placeholder"/>
          </div>
          <div
            class="form-group mb-4 textOfAge flex-center"
            *ngIf="customerForm.controls.cognome.errors && customerForm.controls.cognome.dirty"
            style="margin-top: -1.3rem">
            Inserire un campo valido
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-10 col-md-10 col-lg-7 col-sm-10 mt-2">
          <div class="form-group">
            <input
              type="text"
              formControlName="codiceFiscale"
              placeholder="Codice Fiscale"
              class="form-control input-placeholder"/>
          </div>
          <div
            class="form-group mb-4 textOfAge flex-center"
            *ngIf="customerForm.controls.codiceFiscale.errors && customerForm.controls.codiceFiscale.dirty"
            style="margin-top: -1.3rem">
            Codice Fiscale errato.
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-10 col-md-10 col-lg-7 col-sm-10 mt-2">
          <div class="form-group">
            <input
              type="text"
              formControlName="email"
              placeholder="E-mail *"
              class="form-control input-placeholder"/>
          </div>
          <div
            class="form-group mb-2 textOfAge flex-center"
            *ngIf="customerForm.controls.email.errors && customerForm.controls.email.dirty"
            style="margin-top: -1.3rem">
            Formato non valido.
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-10 col-md-10 col-lg-7 col-sm-10 mt-2">
          <div class="form-group">
            <input
              type="text"
              formControlName="phone"
              placeholder="Telefono *"
              class="form-control input-placeholder"/>
          </div>
        </div>
        <div
          class="form-group mb-2 w-75 textOfAge flex-center"
          *ngIf="customerForm.controls.phone.errors && customerForm.controls.phone.dirty"
          style="margin-top: -1.3rem">
          Formato non valido.
        </div>
      </div>
      <div class="row justify-content-center">
      <div class="col-10 col-md-10 col-lg-7 col-sm-10 mt-2">
        <div class="form-group">
          <input type="date"  formControlName="dataNascita" class="form-control"
            placeholder="Data di nascita *">

        </div>
        <div
          *ngIf="customerForm.controls.dataNascita.errors?.inValidAge && customerForm.controls.dataNascita.dirty"
          class="textOfAge flex-center text-center" style="margin-top: -1.3rem;">
          Per procedere devi avere almeno 18 anni.
        </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-10 col-lg-7 my-4">
          <button
            type="submit"
            class="btn form-control button-primary"
            value="procedi"
            [disabled]="customerForm.invalid">
            Salva
          </button>
        </div>
      </div>
    </form>
</div>
