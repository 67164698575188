import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {Router} from '@angular/router';
import { CustomerModel } from 'src/app/models/customer';
import { UtilsService } from 'src/app/services/utils.service';
import {TrialService} from '../../services/trial.service';

@Component({
  selector: 'app-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.scss']
})
export class EditCustomerComponent implements OnInit {

  customerForm: FormGroup = new FormGroup({});
  purchaseId:string ='';

  // Regex
  nameValidation = '^[^-\\s][a-zA-Z\\s]+$';
  patternCF = new RegExp(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i);
  emailRegExp: RegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  phoneRegExp = '^[0-9-+()*#.,; ]*$';

  constructor(private trialService: TrialService, private router: Router,
    private us: UtilsService,private datePipe:DatePipe) { 

    if (this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation().extras.state) {
      this.purchaseId = this.router.getCurrentNavigation().extras.state.purchaseId;
      
    }
  }

  ngOnInit(): void {
    this.customerFormInit();
    this.fillValues();
  }

  fillValues():void {
    
    this.trialService.getCustomer(parseInt(this.purchaseId)).subscribe((res:CustomerModel)=>{
      this.customerForm.setValue({
        nome:res.nome,
        cognome:res.cognome,
        codiceFiscale:res.codiceFiscale,
        email:res.email,
        phone:res.phone,
        dataNascita:formatDate(res.dataNascita,'yyyy-MM-dd','en')
      });
    })

  }

  customerFormInit(): void{
    this.customerForm.addControl('nome', new FormControl('', [Validators.required, Validators.pattern(this.nameValidation)]));
    this.customerForm.addControl('cognome', new FormControl('', [Validators.required, Validators.pattern(this.nameValidation)]));
    this.customerForm.addControl('codiceFiscale', new FormControl('', [Validators.pattern(this.patternCF)]));
    this.customerForm.addControl('email', new FormControl('', [Validators.required, Validators.pattern(this.emailRegExp)]));
    this.customerForm.addControl('phone', new FormControl('', [Validators.required, Validators.pattern(this.phoneRegExp)]));
    this.customerForm.addControl('dataNascita', new FormControl('', [Validators.required, this.us.validateDate]));
  }

  submitModify(): void {
    
    const nome: string = this.customerForm.get('nome').value;
    const cognome: string = this.customerForm.get('cognome').value;
    const codiceFiscale: string = this.customerForm.get('codiceFiscale').value;
    const email: string = this.customerForm.get('email').value;
    const telefono: number = this.customerForm.get('phone').value;
    const dataNascita = this.customerForm.get('dataNascita').value;
    this.trialService.modifyCustomer(parseInt(this.purchaseId),nome, cognome, codiceFiscale, email, telefono,dataNascita).subscribe(res => {
      console.log(res);
      this.router.navigate(['lista-trials']);
    }, err => {
      console.log(err);
    });
  }

  goBack(): void{
    this.router.navigate(['lista-trials']);
  }

}
