import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  @Input() public path: string;
  title: string;
  profileName: string;
  excludedRedirectPaths = ['/modifica-anagrafica'];
  neededPaths = ['/iqos-kit', '/supporto', '/lista-trials', '/iqos-kit/step-1', '/iqos-kit/step-2',
    '/iqos-kit/step-3', '/iqos-kit/step-4', '/iqos-kit/step-5', '/motivazione-reso',
    '/resi-effettuati', '/motivazione-reso/receipt', '/motivazione-reso/yousignreturn',
    '/modifica-anagrafica'];
  //This is for dynamic routes when purchase id or something is in url
  //you cannot check for exact includes
  regexPaths =['\/lista-trials\/[0-9]*\/viewreceipt'];
  

  constructor(private router: Router, private location: Location) {
  }
  getProfileName(): string{
    return localStorage.getItem('nome');
  }

  ngOnInit(): void {
  }
  redirect(): void{
    
    const isDesktop = window.innerWidth > 1024; // Check for the back button
    if (this.excludedRedirectPaths.find(x => x === this.router.url) && !isDesktop) {
      this.location.back();
      return;
    }
    
    if(this.regexPaths.find(x=>this.router.url.match(x)) && !isDesktop)
    {
      this.location.back();
      return;
    }
    const principalId = localStorage.getItem('principalId');
    
    const nome = localStorage.getItem('nome');
    sessionStorage.clear();
    localStorage.setItem('principalId', principalId);
    
    localStorage.setItem('nome', nome);
    this.router.navigate(['']);
  }

  isPathProfilo(): boolean {
    return this.path === '/profilo';
  }

  isPathNeeded(): boolean {
    
    var specialPath:string = this.regexPaths.find(x=>this.path.match(x));

    return this.neededPaths.includes(this.path) || (specialPath != null && specialPath !=undefined && specialPath.length > 0) ;
  }

  titleNavBar(): string {
    this.title = this.path;
    if (this.path === '/supporto') {
      return 'Supporto';
    }
    else if (this.path === '/lista-trials') {
      return 'Lista Trial';
    }
    else if (this.path === '/iqos-kit/step-1' || this.path === '/iqos-kit/step-2' || this.path === '/iqos-kit/step-3'
    || this.path === '/iqos-kit/step-4' || this.path === '/iqos-kit/step-5') {
      return 'Nuovo IQOS Kit';
    } else if (this.path.startsWith('/motivazione-reso')) {
      return 'Reso';
    }
    else if (this.path === '/resi-effettuati') {
      return 'Resi Effettuati';
    }
    if (this.path === '/modifica-anagrafica') {
      return 'Anagrafica';
    }
    if(this.path.match("\/lista-trials\/[0-9]*\/viewreceipt"))
    {
      return 'Visualizza ricevuta';
    }
  }

}

