import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token: string;

  constructor() { }

  login(username, passwod) { }

  forgotPassword(email) {}

  logout(token) {}

  isAuthenticated() {
    return true
  }
}
