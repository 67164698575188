<div class="container">
<div class="row flex-center">
  <div class="col container-content">
    <div class="row">
      <div class="col-10">
        <div class="form-group">
          <input class="form-control input-search" #searchInput name="cerca" placeholder="Cerca">
        </div>
      </div>
      <div class="col-2">
        <img src="assets/icons/rotate-cw.svg" class="mt-3" style="cursor: pointer" (click)="getTrials(barStatus)">
      </div>
    </div>
  </div>
</div>
</div>

<div *ngIf="barStatus !== 'RETURNED'">
  <div class="container-content row mb-5 mt-4" *ngFor="let trial of trials">
    <div class="container-image-trial">
      <img src="{{trial.productImage}}" class="image-product-trial">
    </div>
    <div class="container-product" (click)="open(content, trial)">
      <div class="box">
        <p class="owner row">{{trial.nome}} {{trial.cognome}}</p>
        <div *ngIf="barStatus === 'ACTIVE'">
          <div class="container">
            <!--<img *ngIf="trial.bill === null" src="assets/icons/error-status.svg" class="status-icon">-->
            <!--<img *ngIf="trial.bill !== null" src="assets/icons/success-status.svg" class="status-icon">-->
            <img src="assets/icons/success-status.svg" class="status-icon">
          </div>
        </div>
      </div>
      <div class="row">
        <p class="information-name">IQOS Code</p>
        <p class="information-value">{{trial.productCode}}</p>
      </div>
      <div class="row" *ngIf="barStatus === 'WAIT'">
        <p class="no-credit-card" *ngIf="trial.cardLastNumbers === null">Carta di credito non inserita</p>
        <p class="no-credit-card" *ngIf="trial.cardLastNumbers !== null">Attiva prova</p>
      </div>
      <div class="row" *ngIf="barStatus === 'TRIAL'">
        <p class="information-name">Fine prova</p>
        <p class="information-value">{{trial.endTrial | date: 'dd/MM/yyyy'}}</p>
      </div>
    </div>
  </div>
  <p class="message-no-list">{{isTrialPresent(trials)}}</p>
</div>

<div *ngIf="barStatus === 'RETURNED'">
  <div class="container-content row mb-5 mt-4" *ngFor="let trial of trials">
    <div class="container-image-resi">
      <img src="{{trial.productImage}}" class="image-product-resi">
    </div>
    <div class="container-resi" (click)="open(content, trial)">
      <p class="owner row">{{trial.nome}} {{trial.cognome}}</p>
      <div class="row">
        <p class="information-name">IQOS Code</p>
        <p class="information-value">{{trial.productCode}}</p>
      </div>
      <!--        <p class="no-credit-card" (click)="open(content, trial)">Vedi Dettagli</p>-->
    </div>
    <hr>
  </div>
  <p class="message-no-list">{{isTrialPresent(trials)}}</p>
</div>

<ng-template #content let-modal>
  <div class="row col-md-offset-3 m-l-15 m-t-15">
    <img src="../assets/logo-iqos-blu.svg">
    <img *ngIf="!isViewPhotoSelected" src="assets/icons/x.svg" height="20" width="20" class="flex-column close-modal" (click)="modal.dismiss('Cross Click')">
    <img *ngIf="isViewPhotoSelected" src="assets/icons/x.svg" height="20" width="20" class="flex-column close-modal" (click)="viewReceipt(0)">
  </div>

  <ng-container *ngIf="!isViewPhotoSelected">
    <div class="row justify-content-center mt-5">
      <div class="col-6 flex-center-y">
        <div class="left-image flex-stretch-y">
          <img src="{{trialDetail.productImage}}" class="image-product-modal">
        </div>
      </div>
      <div class="col-6 flex-center-y pl-0 p-r-35">
        <div class="right-title flex-stretch-y">
          <h1 class="title-modal">{{trialDetail.productModel}}</h1>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col">
        <div class="container-informazioni-iqos mb-3">
          <div class="content-informazioni">
            <p class="row title-informazioni">Infomazioni</p>
            <div class="row">
              <p class="name-informazioni">IQOS Code</p>
              <p class="value-informazioni">{{trialDetail.productCode}}</p>
            </div>
            <div class="row" *ngIf="barStatus === 'TRIAL' || barStatus === 'REFUSED'">
              <p class="name-informazioni">Data fine prova</p>
              <p class="value-informazioni">{{trialDetail.endTrial | date: 'dd/MM/yyyy'}}</p>
            </div>
            <div class="row" *ngIf="barStatus === 'ACTIVE'">
              <p class="name-informazioni">Data acquisto</p>
              <p class="value-informazioni">{{trialDetail.paidOnTimestamp | date: 'dd/MM/yyyy'}}</p>
            </div>
            <div class="row">
              <p [ngClass]="barStatus === 'REFUSED'? 'error-name-informazioni' : 'name-informazioni'">
                Metodo di pagamento
              </p>
              <p [ngClass]="barStatus === 'REFUSED'? 'error-value-informazioni' : 'value-informazioni'"
                 *ngIf="!trialDetail.cardLastNumbers">
                Carta non inserita
              </p>
              <p [ngClass]="barStatus === 'REFUSED'? 'error-value-informazioni' : 'value-informazioni'"
                 *ngIf="trialDetail.cardLastNumbers">
                *{{trialDetail.cardLastNumbers}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="mb-3"
             [ngClass]="barStatus === 'RETURNED' ? 'container-dati-cliente' : 'container-dati-cliente-other'">
          <div class="content-dati">
            <p class="row title-dati">Dati Cliente</p>
            <div class="row">
              <p class="name-dati col p-0">Nome e Cognome</p>
              <p class="value-dati col p-0 text-right">{{trialDetail.nome}} {{trialDetail.cognome}}</p>
            </div>
            <div class="row">
              <p class="name-dati col p-0">Data di nascita</p>
              <p class="value-dati col p-0 text-right">{{trialDetail.birthdate | date: 'dd/MM/yyyy'}}</p>
            </div>
            <div class="row">
              <p class="name-dati col p-0">Email</p>
              <p class="value-dati col p-0 text-right">{{trialDetail.email}}</p>
            </div>
            <div class="row">
              <p class="name-dati col p-0">Telefono</p>
              <p class="value-dati col p-0 text-right">{{trialDetail.telefono}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col" *ngIf="barStatus === 'RETURNED'">
        <div class="container-motivazioni-reso">
          <div class="content-informazioni">
            <p class="row title-informazioni">Motivazioni del reso</p>
            <div class="row">
              <div *ngFor="let answer of trialDetail.surveyAnswers" class="w-100">
                <p class="name-informazioni" *ngIf="answer.answer == true">{{answer.question}}</p>
                <p class="name-informazioni" *ngIf="answer.answer != true">{{answer.answer}}</p>
              </div>
              <div *ngIf="!trialDetail.surveyAnswers.length">
                <p class="name-informazioni">Non ci sono motivazioni per questo reso</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-column flex-center m-t-35 m-b-50">
      <button type="submit" class="btn button-reso button-primary" *ngIf="trialDetail.cardLastNumbers && !isMessageCanceled
      && !isMessageActivated && barStatus === 'WAIT'" (click)="activateTrial(trialDetail.id)">
        Attiva inizio prova
      </button>
      <button type="submit" class="btn button-reso button-secondary mt-3" *ngIf="trialDetail.cardLastNumbers && !isMessageCanceled
      && !isMessageActivated && barStatus === 'WAIT'" (click)="viewReceipt(trialDetail.id)">
      Visualizza scontrino
      </button>
      <button type="submit" class="btn button-reso button-secondary mt-3" *ngIf="trialDetail.cardLastNumbers && !isMessageCanceled
      && !isMessageActivated && barStatus === 'WAIT'" (click)="pictureReceipt(trialDetail.id)">
      Foto scontrino
      </button>
      <button type="submit" class="btn button-reso button-secondary mt-3" *ngIf="barStatus === 'WAIT' && !isMessageCanceled
      && !isMessageActivated && barStatus === 'WAIT'" (click)="cancelTrial(trialDetail.id)">
        Annulla Vendita
      </button>
      <p *ngIf="isMessageCanceled" class="text-danger">La vendita è stata annullata</p>
      <p *ngIf="isMessageActivated" class="text-success">La prova è stata attivata</p>
      <!--<form [formGroup]="scontrinoForm" class="w-100" *ngIf="trialDetail.bill == null">
        <ng-container *ngIf="this.trialDetail.cardLastNumbers && this.barStatus === 'ACTIVE'">
          <div class="row justify-content-center">
            <div class="col-3">
              <input id="file1" type="file" class="inputfile" (change)="caricaScontrino(this.trialDetail.id, $event.target.files)" formControlName="scontrino" />
              <label for="file1">Carica scontrino</label>
            </div>
          </div>
        </ng-container>
      </form>-->

      <!-- <div *ngIf="trialDetail.bill !== null">
         <div class="flex-row flex-center">
           <button type="submit" class="btn button-reso button-secondary mt-3" (click)="downloadFile()">Vedi scontrino</button> -->
      <!-- <img src="assets/icons/arrow-left.svg" class="rotated m-l-5"> -->
      <!--</div>
    </div> -->

      <button type="submit" class="btn button-reso form-control button-primary"
              *ngIf="trialDetail.cardLastNumbers && barStatus === 'TRIAL'"
              (click)="returnTrial(trialDetail.id)">Effettua Reso
      </button>
      <button type="submit" class="btn form-control button-reso button-secondary mt-4"
              *ngIf="trialDetail.cardLastNumbers && barStatus === 'TRIAL'"
              (click)="resendEmail(trialDetail.id, false)">Reinvia E-mail
      </button>
      <button type="submit" class="btn form-control button-reso button-primary mt-4"
              *ngIf="trialDetail.cardLastNumbers && barStatus === 'REFUSED'"
              (click)="resendEmail(trialDetail.id, true)">Invia Mail Pagamento
      </button>
      <button type="submit" class="btn form-control button-reso button-secondary mt-4"
              *ngIf="trialDetail.cardLastNumbers && (barStatus === 'TRIAL' || barStatus === 'REFUSED')"
              (click)="payInCash(trialDetail.id)">
        Pagato in contanti
      </button>
      <button type="submit" class="btn form-control button-reso button-secondary mt-4"
              *ngIf="trialDetail.cardLastNumbers && barStatus === 'TRIAL'"
              (click)="goToEditCustomer(trialDetail.id)">
        Modifica anagrafica
      </button>
      <button type="submit" class="btn form-control button-reso button-secondary mt-4"
              *ngIf="trialDetail.cardLastNumbers && barStatus === 'TRIAL'"
              (click)="viewReceipt(trialDetail.id)">
        Visualizza scontrino
      </button>
      <button type="submit" class="btn form-control button-reso button-secondary mt-4"
              *ngIf="trialDetail.cardLastNumbers && barStatus === 'TRIAL'"
              (click)="pictureReceipt(trialDetail.id)">
        Foto scontrino
      </button>
      <div *ngIf="isMessageSent">
        <p class="text-success">L'E-mail è stata inviata con successo!</p>
      </div>
    </div>
  </ng-container>
</ng-template>
