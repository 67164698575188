import {Component, OnInit} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {RetailerService} from 'src/app/services/retailer.service';
import {RetailerStatus} from 'src/app/models/retailer';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {GenericModalComponent} from 'src/app/shared/generic-modal/generic-modal.component';


const style = {
  base: {
    lineHeight: '3.25',
    color: '#32325d',
    fontSize: '16px',
    '::placeholder': {
      color: '#999'
    },
    ':-webkit-autofill': {
      color: '#32325d',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    ':-webkit-autofill': {
      color: '#fa755a',
    },
  }
};
const options = {
  style,
  supportedCountries: ['SEPA'],
  placeholderCountry: 'IT',
};

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {
  public YousignURL:string ='';
  public username:string='';
  phrase = 'Per procedere con il login, accetta e firma il contratto di licenza d\'uso e servizi connessi che trovi in seguito';

  modalRef: NgbModalRef;
  modalOptions: NgbModalOptions = {
    centered: true
  }
  constructor(private router: Router,private retailerService:RetailerService,private modalService: NgbModal) {
    if (this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation().extras.state) {
      this.username = this.router.getCurrentNavigation().extras.state.username;


    }
  }
  ngOnInit(): void {
     //Call API to get the DSTech agreement
     this.retailerService.getRetailerContract(this.username).subscribe((obj)=>{
       this.YousignURL= obj["url"];
     },(err)=>{
       console.log(err);
     })

  }
  OnProceed():void {
    //Check if signed and then move forward
    this.retailerService.getRetailerStatus(this.username).subscribe((obj:RetailerStatus)=>{
      if(obj.status == "DISABLED_CONTRACT_SIGNED")
      {
        let navigationExtras: NavigationExtras = {
          state: {
            Phrase1: 'Inizializzazione account in corso',
            Phrase2: "Per poter accedere all'applicazione bisogna attendere che il contratto venga controfirmato.",
            Phrase3: "Entro 24 ore l'accesso all'app sarà abilitato e potrà venire utilizzata."
          }
        };
        //you will move to same page with different text when it is not approved
        this.router.navigate(['/retailersignsuccess'],navigationExtras);
      }
      else
      {
        this.modalRef = this.modalService.open(GenericModalComponent, this.modalOptions);
        this.modalRef.componentInstance.message = "Contract Not Signed";

      }

  })
}

}
