<!-- Begin Camera -->
<div class="container">
  <div class="text-center mt-5">
     <img src="assets/logo-iqos-blu.svg" alt="IQOS logo">
     </div>
       <div class="row">
           <div class="col">
               <div>
                   <p class="text-center">Inquadra lo scontrino poi premi il pulsante in fondo alla pagina per fotografare</p>
               </div>
           </div>
       </div>
   </div>
   <div *ngIf="isCameraOpen" class="camera-section-main">
       <div class="camera-section">
           <ng-container *ngIf="!isCameraError">
             <div class="camera-icon"><a (click)="capture()" ><img src="assets/icons/camera-icon.png" width="75" height="75" /></a></div>
             <video #video class="video-fluid" id="video" autoplay></video>
           </ng-container>
            <ng-container *ngIf="isCameraError">
              <div class="d-flex flex-center-x flex-center-y h-100">
                <p>{{cameraErrorText}}</p>
              </div>
            </ng-container>
       </div>
   </div>
   <div *ngIf="!isCameraOpen" class="camera-section-main">
    <div class="camera-section">
      <img id="img-receipt" src="{{ imgReceipt }}" class="flex-center-x img-fluid"/>
    </div>

  </div>
   <div class="camera-action-icons">
       <ul>
           <li>
               <a (click)="nextStep()" href="javascript:void(0)">
                   <img src="assets/icons/x.svg" width="20" height="20" />
                   <span class="txt">Annulla Foto</span>
               </a>
           </li>
           <li>
               <a *ngIf="!isCameraOpen" (click)="openCamera()" href="javascript:void(0)">
                   <img src="assets/icons/arrow-left.svg" width="20" height="20" />
                   <span class="txt">Riprova</span>
               </a>
           </li>
           <li *ngIf="!isCameraError">
               <a (click) ="save()" href="javascript:void(0)">
                   <img src="assets/icons/arrow-right.svg" width="20" height="20" />
                   <span class="txt">Procedi</span>
               </a>
           </li>
           <li class="upload-image">
            <label for="file-upload" class="custom-file-upload">
                <img src="assets/icons/image.svg" width="20" height="20" />
                <span class="txt">Carica foto</span>
            </label>
            <input id="file-upload" type="file" accept="image/*" (change)="caricaScontrino($event.target.files)"/>
          </li>
       </ul>
   </div>
<canvas #canvas width="{{ width }}" height="{{ height }}" class="d-none"></canvas>
   <!-- End Camera -->
