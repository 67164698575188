import { Injectable } from '@angular/core';
import { CanLoad, Router} from "@angular/router";
import { AuthService } from "../features/authentication/services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanLoad {

  constructor(private auth: AuthService, private router: Router) { }

  canLoad(): boolean {
    if (localStorage.getItem("token") == null) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }

  canActivate(): boolean {
    if (localStorage.getItem("token") == null) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }

}
