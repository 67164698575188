import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { RetailerService } from '../../../services/retailer.service';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { UtilsService } from '../../../services/utils.service';
import { Retailer, RetailerStatus } from '../../../models/retailer';
import { RetailerProfile } from '../../../models/retailer-profile';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isErrorMessage = false;
  closeResult: string;
  loginForm: FormGroup = new FormGroup({});
  resetPasswordForm: FormGroup = new FormGroup({});
  emailRegExp: RegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  constructor(
    private authService: AuthService,
    private router: Router,
    private utilsService: UtilsService,
    private modalService: NgbModal,
    private retailerService:RetailerService
  ) { }

  ngOnInit(): void {
    this.loginForm.addControl('username', new FormControl('', Validators.required));
    this.loginForm.addControl('password', new FormControl('', Validators.required));
    this.resetPasswordForm.addControl('email', new FormControl('', [Validators.required, Validators.pattern(this.emailRegExp)]));
  }

  setAgreementStatus(user:string,password:string)
  {
    this.retailerService.getRetailerStatus(user).subscribe((obj:RetailerStatus)=>{

      if(obj.status == "ENABLED")
      {

        this.authService.login(user, password).subscribe(
        result => {
          const jsonString = JSON.stringify(result);
          const retailer: Retailer = JSON.parse(jsonString);
          localStorage.setItem('principalId', String(this.utilsService.getIdBySelfHref(retailer._links.self.href)));
          this.setProfile();

        }, error => {
          console.log(error);
          if (error.status === 401)
            this.isErrorMessage = true;
        });
      }
      else if(obj.status == "DISABLED")
      {
        let navigationExtras: NavigationExtras = {
          state: {
            username:user
          }
        };
        this.router.navigate(['onboarding'],navigationExtras);
      }
      else if(obj.status == "DISABLED_CONTRACT_SIGNED")
      {
        let navigationExtras: NavigationExtras = {
          state: {
            Phrase1: 'Inizializzazione account in corso',
            Phrase2: "Per poter accedere all'applicazione bisogna attendere che il contratto venga controfirmato.",
            Phrase3: "Entro 24 ore l'accesso all'app sarà abilitato e potrà venire utilizzata."
          }
        };
        //you will move to same page with different text when it is not approved
        this.router.navigate(['/retailersignsuccess'],navigationExtras);
      }

    },(err)=>{
      console.log(err);
      this.isErrorMessage = true;
    })
  }

  setProfile()
  {
    this.authService.getMyProfile().subscribe(
      (result: RetailerProfile) => {

        localStorage.setItem('nome', result.nome);
        this.isErrorMessage = false;
        localStorage.setItem("agreement","1");
        this.router.navigate(['home']);


      },
      error => {
        console.log("err", error);
      }
    );

  }

  login(): void {
    const user: string = this.loginForm.get("username").value;
    const password: string = this.loginForm.get("password").value;
    //Typically done in logout but since it is not there removing it
    //so that a new user gets in the value gets refreshed properly
    localStorage.removeItem("agreement");
    this.setAgreementStatus(user,password);

  }
  resetPassword(): void {
    const email: string = this.resetPasswordForm.get('email').value;
    this.authService.resetPasword(email).subscribe(
      result => {
        this.modalService.dismissAll();
        let navigationExtras: NavigationExtras = {
          state: {
            Phrase1: 'Operazione Completata',
            Phrase2: 'Ti è stato inviato un link per resettare la tua password.',
            Phrase3: 'Controlla la mail'
          }
        };
        this.router.navigate(['/retailersignsuccess'], navigationExtras);
      }, error => {
        console.log(error);
      }
    );
  }
  open(content): void {
    this.modalService.open(content, { size: 'md', ariaLabelledBy: 'modal-basic-title', centered: true })
      .result.then((result) => {
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
