import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { HTTPStatus } from './services/interceptor.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent{

  HTTPActivity: boolean = false;

  constructor(private router: Router, private httpStatus: HTTPStatus) {
    this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
      this.HTTPActivity = status;
    });
  }

  path(): string{
    return this.router.url;
  }

}
