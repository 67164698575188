import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {ContractService} from 'src/app/services/contract.service';
import {TrialService} from 'src/app/services/trial.service';
import {HTTPStatus} from '../../services/interceptor.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit, AfterViewInit {

  public captures: Array<any>;
  private purchaseId = '';

  attempt = 0;
  imgReceipt;
  height = window.innerHeight;
  width = window.innerWidth;
  isCameraOpen = true;
  isPictureTaken = false;
  isShotLoaded = false;
  isCameraError = false;
  cameraErrorText = '';
  navigator;
  path = '';

  constraints = {
    video: {
      width: {
        min: 1280,
        ideal: 1920,
        max: 2560,
      },
      height: {
        min: 720,
        ideal: 1080,
        max: 1440
      },
      facingMode: 'environment'
    }
  };

  @ViewChild('video')
  public video: ElementRef;

  @ViewChild('canvas')
  public canvas: ElementRef;

  @ViewChild('receipt')
  public receipt: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (this.isCameraOpen) {
      setTimeout(() => {
        this.isCameraOpen = false;
        this.openCamera();
      }, 1000);
    }
  }

  constructor(private router: Router, public status: HTTPStatus,
              private contractService: ContractService, private trialService: TrialService) {
    this.captures = [];
    if (sessionStorage.getItem('purchaseId') !== null) {
      this.purchaseId = sessionStorage.getItem('purchaseId');
    }
  }

  ngAfterViewInit(): void {
    this.openCamera();
  }

  ngOnInit(): void {
    this.path = this.router.url;
  }

  public capture(): void {
    this.height = this.video.nativeElement.offsetHeight;
    this.width = this.video.nativeElement.offsetWidth;
    let quality = 1;
    let image = '';
    do {
      const context = this.canvas.nativeElement.getContext('2d');
      context.drawImage(this.video.nativeElement, 0, 0, this.width, this.height);
      image = this.canvas.nativeElement.toDataURL('image/jpeg', quality);
      quality = quality - .1;
    } while (this.getSize(image) >= 1000);
    this.imgReceipt = image;
    this.isCameraOpen = false;
    this.isPictureTaken = true;
    console.log(`Image size: ${this.getSize(this.imgReceipt)}KB, Quality: ${quality + .1}`);
    this.closeCamera();
  }

  async openCamera(): Promise<any> {
    setTimeout(() => {
      this.status.setHttpStatus(true);
    }, 0);
    this.isPictureTaken = false;
    this.isCameraOpen = true;
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(this.constraints);
        this.video.nativeElement.srcObject = stream;
        this.video.nativeElement.play();
        this.navigator = stream.getTracks()[0];
        setTimeout(() => {
            this.height = this.video.nativeElement.offsetHeight;
            this.width = this.video.nativeElement.offsetWidth;
            this.isShotLoaded = true;
            this.status.setHttpStatus(false);
          }, 1000);
      } catch (e) {
        this.handleCameraError(e);
      }
    }
  }

  handleCameraError(e): void {
    this.isCameraError = true;
    if (e.name === 'NotAllowedError'){
      this.cameraErrorText = 'Non sono stati consentiti i permessi all\'utilizzzo della webcam sul tuo dispositivo';
    } else if (e.name === 'OverconstrainedError') {
      this.cameraErrorText = 'La risoluzione del tuo dispositivo non è supportata';
    } else {
      this.cameraErrorText = 'Il tuo browser non è compatibile per lo scatto della foto';
    }
    this.status.setHttpStatus(false);
  }


  ProcediSuccess(): void {
    if (this.router.url.split('/')[1] === 'lista-trials') {
      this.closeCamera();
      let navigationExtras: NavigationExtras = {};

      navigationExtras = {
        state: {
          frase1: 'Operazione Completata',
          frase2: 'La foto della ricevuta',
          frase3: 'è stata salvata con successo!',
        }
      };


      this.router.navigate(['success'], navigationExtras);
    } else {
      this.trialService.activateTrial(this.purchaseId).subscribe(
        result => {
          this.closeCamera();
          let navigationExtras: NavigationExtras = {};
          navigationExtras = {
            state: {
              frase1: 'Operazione Completata',
              frase2: 'La carta di credito',
              frase3: 'è stata registrata con successo.',
              frase4: 'Il periodo di prova è stato avviato!',
            }
          };
          this.router.navigate(['success'], navigationExtras);

        });

    }

  }

  save(): void {
    if (this.isCameraOpen) {
      this.capture();
    }
    const base64 = fetch(this.imgReceipt).then((res) => {
      res.blob().then((blob) => {
        const fd = new FormData();
        const file = new File([blob], 'filename.jpeg');
        fd.append('file', file);
        this.contractService.postRecieptPhoto(Number(this.purchaseId), fd).subscribe(res => {
          this.ProcediSuccess();

        });
      });
    });
  }

  nextStep(): void {
    if (this.router.url.split('/')[1] === 'lista-trials') {
      // no activation from lista-trials
      let navigationExtras: NavigationExtras = {};
      navigationExtras = {
        state: {
          frase1: 'Operazione Annullata',
          frase2: '',
          frase3: '',
          frase4: ''
        }
      };
      this.closeCamera();
      this.router.navigate(['success'], navigationExtras);


    } else {
      // activate the trial if coming from retailer only
      this.trialService.activateTrial(this.purchaseId).subscribe(
        result => {
          let navigationExtras: NavigationExtras = {};

          navigationExtras = {
            state: {
              frase1: 'Operazione Completata',
              frase2: 'La carta di credito',
              frase3: 'è stata registrata con successo.',
              frase4: 'Il periodo di prova è stato avviato!',
            }
          };

          this.router.navigate(['success'], navigationExtras);
          this.closeCamera();


        });
    }
  }

  goBack(): void {
    this.closeCamera();
    this.router.navigate([this.router.url.split('/')[1]]);
  }

  closeCamera(): void {
    this.navigator.stop();
  }

  getSize(image: string): number {
    const stringLength = image.length - 'data:image/jpeg;base64,'.length;
    const sizeInBytes = 4 * Math.ceil((stringLength / 3));
    return sizeInBytes / 1000;
  }

  caricaSuccess(): void {
    if (this.router.url.split('/')[1] === 'lista-trials') {
      this.closeCamera();
      const navigationExtras: NavigationExtras = {
        state: {
          frase1: 'Operazione Completata',
          frase2: '',
          frase3: 'Scontrino inviato con successo'
        }
      };
      this.router.navigate(['success'], navigationExtras);
    } else {
      this.trialService.activateTrial(this.purchaseId).subscribe(
        result => {

          this.closeCamera();
          const navigationExtras: NavigationExtras = {
            state: {
              frase1: 'Operazione Completata',
              frase2: '',
              frase3: 'Scontrino inviato con successo'
            }
          };
          this.router.navigate(['success'], navigationExtras);

        });
    }

  }

  caricaScontrino(scontrino: any): void {
    const scontrinoFile = scontrino[0];
    const formData = new FormData();
    formData.append('file', scontrinoFile);
    this.contractService.postRecieptPhoto(parseInt(this.purchaseId), formData).subscribe(res => {

      this.caricaSuccess();


    }, error => {
    });

  }

}
