// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  //TEST
  production: false,
  endpointUri: 'https://iqos.dstech.info/iqos',

  XPayEnvironment: 'INTEG',
  nexiUrlScript: 'https://int-ecommerce.nexi.it/ecomm/XPayBuild/js?alias=',
  nexiApiKey : 'ALIAS_RICO_00032571',
  nexiMacKey : 'YEXBF6ZUVK45A84Z37Z53QTOCL80XBAX',
  YouSignBaseURL: 'https://staging-app.yousign.com'

  //PROD
  //XPayEnvironment: 'PROD',
  //nexiUrlScript: 'https://ecommerce.nexi.it/ecomm/XPayBuild/js?alias=',
  //nexiApiKey : 'payment_3494756',
  //nexiMacKey : '89C93PbvLh8gZz4KS28JJDp1B9CVdhD08kq1M182',
  //endpointUri: 'https://www.provaiqosper30giorni.it/iqos',
  //stripeKey: 'pk_live_51HRDvmI14YsLSLCIIGImHJHgYRgfa1OEuZxBbJBK5Z2nZfMDqUa7pLRWc2lqBacSMtfx5sPsPVDR3L23NQduvv0c004rwlRkQE',
  // YouSignBaseURL: 'https://webapp.yousign.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
