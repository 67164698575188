import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-yousign',
  templateUrl: './yousign.component.html',
  styleUrls: ['./yousign.component.scss']
})
export class YousignComponent implements OnInit,OnChanges {

  @Input() yousignurl:string ='';
  public safeURL:SafeResourceUrl;
  @Output() OnProceedEvent:EventEmitter<void> =  new EventEmitter<void>()
  constructor(private sanitizer:DomSanitizer) { }

  ngOnInit(): void {
   
  }
  OnProceed():void {

    this.OnProceedEvent.emit();
  }
  ngOnChanges(simpleChange:SimpleChanges)
  {
    if(simpleChange && simpleChange.yousignurl && !simpleChange.yousignurl.firstChange)
    {
      this.safeURL=this.sanitizer.bypassSecurityTrustResourceUrl(this.yousignurl);
    }
  }

}
