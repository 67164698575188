import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {RetailerProfile} from '../../models/retailer-profile';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  profileName: string = '';
  
  macKey = '';
  apiKey = '';

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    this.profileName = localStorage.getItem('nome');
    this.authService.getMyProfile().subscribe(
      (result: RetailerProfile) => {
        
        localStorage.setItem('nome', result.nome);
        this.router.navigate(['home']);
      },
      error => {
        console.log("err", error);
      }
    );
  }
  nuovaIqos(): void {
    this.router.navigateByUrl('iqos-kit/step-1');
  }
  listaTrial(): void {
    this.router.navigateByUrl('lista-trials');
  }
  resiEffettuati(): void {
    this.router.navigateByUrl('resi-effettuati');
  }
  supporto(): void {
    this.router.navigateByUrl('supporto');
  }
  profilo(): void {
    this.router.navigateByUrl('profilo');
  }
}
