import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Trial} from '../../../models/trial';
import {TrialService} from '../../../services/trial.service';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {NavigationExtras, Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {PlatformLocation} from '@angular/common';
import { Answer } from '../../../models/answer';
import { GenericModalComponent } from 'src/app/shared/generic-modal/generic-modal.component';
import { ContractService } from 'src/app/services/contract.service';
import { DomSanitizer } from '@angular/platform-browser';
import {ConfirmationModalComponent} from '../../../shared/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-list-items',
  templateUrl: './list-items.component.html',
  styleUrls: ['./list-items.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListItemsComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() barStatus: string;
  @ViewChild('searchInput') searchInput: ElementRef;
  closeResult: string;
  trialDetail: Trial = new Trial();
  trials: Trial[] = new Array<Trial>();
  scontrinoForm: FormGroup;
  isMessageCanceled = false;
  isMessageActivated = false;
  isMessageSent = false;
  isViewPhotoSelected = false;
  receiptImage;
  constructor(private modalService: NgbModal,
              private trialService: TrialService,
              private contractService:ContractService,
              private domSanitizer:DomSanitizer,
              private router: Router,
              private platformLocation: PlatformLocation
  ) {
    platformLocation.onPopState(() => {
      modalService.dismissAll();
    });
  }

  ngOnInit(): void {
    this.scontrinoForm = new FormGroup({
      scontrino: new FormControl(''),
    });
  }

  ngAfterViewInit(): void {
    this.searchTrials();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.barStatus !== undefined && changes.barStatus.currentValue !== undefined &&
      changes.barStatus.currentValue !== changes.barStatus.previousValue) {
      this.barStatus = changes.barStatus.currentValue;
      this.getTrials(this.barStatus);
    }
    if (changes.searchInput !== undefined && changes.searchInput.currentValue !== undefined &&
      changes.searchInput.currentValue !== changes.searchInput.previousValue) {
    }
  }

  isTrialPresent(trials): string {
    if ((trials.length === null || trials.length === 0) && this.barStatus === 'WAIT') {
      return 'Non ci sono trial in attesa';
    } else if ((trials.length === null || trials.length === 0) && this.barStatus === 'TRIAL') {
      return 'Non ci sono trial per ora';
    } else if ((trials.length === null || trials.length === 0) && this.barStatus === 'REFUSED') {
      return 'Non ci sono trial respinti';
    } else if ((trials.length === null || trials.length === 0) && this.barStatus === 'ACTIVE') {
      return 'Non ci sono trial attivi';
    } else if ((trials.length === null || trials.length === 0) && this.barStatus === 'RETURNED') {
      return 'Non ci sono resi';
    }
  }

  searchTrials(): void {
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        if (!event.target.value) {
          this.trials = [];
        }
        return event.target.value;
      })
      , debounceTime(1000)
      , distinctUntilChanged()
    ).subscribe((text: string) => {
      this.trialService.searchTrial(this.barStatus, text).subscribe((res) => {
        this.trials = res._embedded.purchases;
      }, (err) => {
      });
    });
  }

  getTrials(status): void {
    this.trialService.getTrials(status).subscribe(
      result => {
        this.trials = result._embedded.purchases;
      }
    );
  }

  activateTrial(idPurchase: number): void {
    this.trialService.activateTrial(String(idPurchase)).subscribe(
      result => {
        this.isMessageActivated = true;
      }, error => {
        console.log(error);
      }
    );
  }

  cancelTrial(idPurchase): void {
    this.trialService.cancelTrial(idPurchase).subscribe(
      result => {
        this.isMessageCanceled = true;
      }, error => {
        console.log(error);
      }
    );
  }

  returnTrial(idTrial: number): void {
    sessionStorage.setItem('idTrial', String(idTrial));
    this.router.navigate(['motivazione-reso']);
    this.modalService.dismissAll();
  }
  resendEmail(idTrial: number, isAfterTrial): void{
    this.trialService.resendEmail(idTrial, isAfterTrial).subscribe(
      result => {
        this.isMessageSent = true;
        setTimeout(() => {
          this.isMessageSent = false;
        }, 5000);
      }, error => {
        console.log(error);
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (this.isMessageCanceled || this.isMessageActivated) {
      this.isMessageCanceled = false;
      this.isMessageActivated = false;
      this.getTrials('WAIT');
      return;
    }
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  open(content, trial): void {
    this.modalService.open(content, {size: 'md'})
      .result.then((result) => {
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.trialDetail = trial;
  }

  downloadFile() {
    this.trialService.downloadBill(this.trialDetail.bill).subscribe(
      res => {
        let url = URL.createObjectURL(res.body);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = 'scontrino_' + this.trialDetail.productCode + '.pdf';
        a.target = '_blank';
        a.click();
      },error => {

      }
    );
  }

  listItems(): void {
    if (this.barStatus === 'prova') {
    } else if (this.barStatus === 'attesa') {
    } else if (this.barStatus === 'attivi') {
    } else {
    }
  }

  payInCash(idTrial: number): void{
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.titleBox = 'Conferma Pagamento';
    modalRef.componentInstance.message = 'Premendo "Accetta" confermerai che il cliente ha finalizzato l\'acquisto pagando in contanti';
    modalRef.result.then((state) => {
      if (state) {
        console.log(state);
        this.trialService.payInCash(idTrial).subscribe(res => {
          window.location.reload();
        });
      }
    }).catch( err => {
      console.log(err);
    });

  }

  goToEditCustomer(idTrial: number): void {

    const navigationExtras: NavigationExtras = {
      state: {
       purchaseId:idTrial
      }
    };
    this.router.navigate(['modifica-anagrafica'],navigationExtras);
    this.modalService.dismissAll();
  }

  viewReceipt(idTrial: number): void{
    this.modalService.dismissAll();
    this.router.navigate(["lista-trials/" + idTrial + "/viewreceipt"]);



  }

  pictureReceipt(idTrial: number): void{
    this.modalService.dismissAll();
    sessionStorage.setItem('purchaseId', String(idTrial));
    this.router.navigate(['lista-trials/ricevuta']);
  }

  caricaScontrino(idTrial: number, scontrino: any): void {
    let scontrinoFile = scontrino[0];
    const formData = new FormData();
    formData.append('file', scontrinoFile);
    this.trialService.uploadBill(idTrial, formData).subscribe(res => {
      this.modalService.dismissAll();
      let navigationExtras: NavigationExtras = {
        state: {
          frase1: 'Operazione Completata',
          frase2: '',
          frase3: 'Scontrino inviato con successo'
        }
      };
      this.router.navigate(['success'], navigationExtras);
    }, error => {
    });

  }

}
