<div id="background-image">
  <div class="ml-1">
    <img src="../../../assets/logo-iqos-white.svg" class="ml-4 m-t-25">
    <div class="ml-auto mr-3 mt-3 avatar-container" (click)="profilo()">
      <div class="avatar-badge">
        <img src="assets/home/user-avatar.svg" class="avatar-user">
      </div>
      <div class="avatar-name flex-column flex-end-x">
        <h5 class="">{{profileName}}</h5>
      </div>
    </div>
  </div>
  <div class="container-title">
    <h1 class="title">Try IQOS Before You Buy</h1>
    <!--      <h1 class="title">Before You Buy</h1>-->
  </div>
  <div class="container m-t-45">
    <div class="row justify-content-center">
      <div class="col">
          <div class="button-card-left flex-stretch-y mb-4" (click)="nuovaIqos()">
            <div class="button-card-icon m-l-10 m-t-10">
              <img src="assets/home/icon-iqos.svg" class="icon">
            </div>
            <p class="button-card-text mb-0 m-l-10">Nuovo IQOS Kit</p>
          </div>
      </div>
      <div class="col">
        <div class="button-card-right flex-stretch-y" (click)="listaTrial()">
          <div class="button-card-icon m-l-10 m-t-10">
            <img src="assets/home/icon-trial.svg" class="icon">
          </div>
          <p class="button-card-text mb-0 m-l-10">Lista Trial</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col">
        <div class="button-card-left flex-stretch-y spacing-top mt-4" (click)="resiEffettuati()">
          <div class="button-card-icon m-l-10 m-t-10">
            <img src="assets/home/icon-trial.svg" class="icon">
          </div>
          <p class="button-card-text mb-0 m-l-10">Resi Effettuati</p>
        </div>
      </div>
      <div class="col">
        <div class="button-card-right flex-stretch-y spacing-top mt-4" (click)="supporto()">
          <div class="button-card-icon m-l-10 m-t-10">
            <img src="assets/home/icon-support.svg" class="icon">
          </div>
          <p class="button-card-text mb-0 m-l-10">Supporto</p>
        </div>
      </div>
    </div>
  </div>
</div>