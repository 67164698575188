import { Injectable, ComponentFactoryResolver, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError, Subject, BehaviorSubject } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';
import { GenericModalComponent } from '../shared/generic-modal/generic-modal.component';

@Injectable()
export class HTTPStatus {

  private requestInFlight$: BehaviorSubject<boolean>;

  constructor() {
    this.requestInFlight$ = new BehaviorSubject(false);
  }

  setHttpStatus(inFlight: boolean) {
    this.requestInFlight$.next(inFlight);
  }

  getHttpStatus(): Observable<boolean> {
    return this.requestInFlight$.asObservable();
  }
}

@Injectable()
export class InterceptorService implements HttpInterceptor {

  restCallCounter: number = 0;
  modalRef: NgbModalRef;
  modalOptions: NgbModalOptions = {
    centered: true
  }

  private requestInFlight$: BehaviorSubject<boolean>;
  constructor(private router: Router, private modalService: NgbModal, private status: HTTPStatus) {
    this.requestInFlight$ = new BehaviorSubject(false);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    this.restCallCounter++;
    setTimeout(() => {
      this.status.setHttpStatus(true);
    }, 0);

    let authReq = req;
    if (req.url != environment.endpointUri + "/principalImpls/search/myPrincipal" && req.url.indexOf("/principal/resetPasswordRequest") == -1
    && !(req.url.startsWith(environment.endpointUri + "/retailers/retailerStatus")) && !(req.url.startsWith(environment.endpointUri + "/retailers/retailerContract"))
    &&  !(req.url.endsWith("/purchaseContractUrl")) &&  !(req.url.endsWith("/customerPaymentUrl"))) {
      authReq = req.clone({
        headers: new HttpHeaders({
          // 'Content-Type':  'application/json',
          'x-auth-token': localStorage.getItem("token")
        })
      });
    }

    return next.handle(authReq)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            if (req.url == environment.endpointUri + "/principalImpls/search/myPrincipal") {
              localStorage.setItem("token", event.headers.get('x-auth-token'));
            }
            return event;
          }
        }),
        catchError(err => {
          this.restCallCounter = 0;
          setTimeout(() => {
            this.status.setHttpStatus(false);
          }, 0);
          if (err instanceof HttpErrorResponse && err.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            this.router.navigate(['login']);
          }
          else if (err instanceof HttpErrorResponse && err.status !== 401) {
            this.modalRef = this.modalService.open(GenericModalComponent, this.modalOptions);
            // Check if the 400 response is receipt's call
            const isReceiptCall = err.url.split('/').pop() === 'bill';
            if (err.error.codice === '600400' || isReceiptCall) {
                this.modalService.dismissAll();
              }
              else if (err.error.message != undefined && err.error.message.length > 0) {
                this.modalRef.componentInstance.message = err.error.message;
              } else {
                this.modalRef.componentInstance.message = err.error.error;
              }
          }
          return throwError(err);
        }),
        finalize(() => {
          this.restCallCounter--;
          if (this.restCallCounter <= 0) {
            setTimeout(() => {
              this.status.setHttpStatus(false);
            }, 0);
          }
        })
      );
  }

}
