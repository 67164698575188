<div class="flex-column flex-center">
  <div class="container-logo">
    <img src="assets/logo-iqos-blu.svg">
  </div>

  <div class="container-title">
    <h2>{{Phrase1}}</h2>
  </div>

  <div class="row mt-4 justify-content-center">
    <div class="col-10 col-md-10 col-lg-10 col-sm-10 mt-2 text-center ">
      <p class="">{{Phrase2}}</p>
      <p class="">{{Phrase3}}</p>
    </div>
  </div>


  <div class="container-button col-10 col-lg-7 flex-center flex-column">

    <button class="form-control button-primary m-t-25 mb-5" (click)="redirectLogin()">Chiudi</button>
  </div>
</div>

