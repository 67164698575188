import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HateoasService } from '../../app/services/hateoas.service';
import { HrefLink } from '../interfaces/generic-links';
import { Observable } from 'rxjs';
const LOGIN_URL = `${environment.endpointUri}/principalImpls/search/myPrincipal`;
const PRINCIPAL_URL = `${environment.endpointUri}/principalImpls/`;
const CUSTOM_PRINCIPAL_URL = `${environment.endpointUri}/principal/`;
const COMPLETE_ONBOARDING_URL = `${environment.endpointUri}/retailers/completeOnboarding`;
// const ONBOARDING_URL = `${environment.endpointUri}/retailers/onboardingInfo`;
// const BANK_ACCOUNT_URL = `${environment.endpointUri}/retailers/onboardingInfo`;
const ONBOARDING_URL = `${environment.endpointUri}/retailers/onboarding`;
// const PROFILE_URL = `${environment.endpointUri}/organizzaziones/search/getMyOrganizzazione?projection=Profile`;
const PROFILE_URL = `${environment.endpointUri}/personaFisicas/search/findPersonaByPrincipalId?principalId=`;
const PROFILE_ORGANIZZAZIONES = `${environment.endpointUri}/organizzaziones/`;
const PRINCIPAL_NUMBER_URL = `${environment.endpointUri}/contattoes/`;
const UPLOAD_URL = `${environment.endpointUri}/retailers/verification`;

UPLOAD_URL
@Injectable({
  providedIn: 'root'
})
export class AuthService extends HateoasService {

  login(username: string, password: string) {
    const pas64 = btoa(username + ':' + password);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + pas64
    });
    return this.http.get(LOGIN_URL, { headers });
  }

  getMyProfile() {
    const profileUrl = PROFILE_URL + this.getPrincipalId() + '&projection=Profile';
    return this.get(profileUrl);
  }
  getPrincipalId(): string {
    return localStorage.getItem('principalId');
  }
  getPrincipalOrganization(link: string) {
    return this.get(link);
  }
  updateEmail(email: string) {
    return this.patchObject(PRINCIPAL_URL + this.getPrincipalId(), { email: email });
  }
  updatePassword(password: string) {
    return this.createObject(PRINCIPAL_URL + 'changePassword?password=' + password, null);
  }
  updateTelephone(idTelefono, numero: number) {
    return this.patchObject(PRINCIPAL_NUMBER_URL + idTelefono, { contact: numero });
  }
  updateRetailerCode(idRetailer: number, retailerCode: string) {
    return this.patchObject(PROFILE_ORGANIZZAZIONES + idRetailer, { userProperties: { retailerCode: retailerCode } });
  }
  updateIban(idRetailer: number, iban: string) {
    return this.patchObject(PROFILE_ORGANIZZAZIONES + idRetailer, { userProperties: { iban: iban } });
  }
  updateIntestatarioConto(idRetailer: number, intestatarioConto: string) {
    return this.patchObject(PROFILE_ORGANIZZAZIONES + idRetailer, { userProperties: { intestatarioConto: intestatarioConto } });
  }
  updatePec(idRetailer: number, pec: string){
    return this.patchObject(PROFILE_ORGANIZZAZIONES + idRetailer, { userProperties: { pec: pec } });
  }
  updateCodiceUnivoco(idRetailer: number, codiceUnivoco: string){
    return this.patchObject(PROFILE_ORGANIZZAZIONES + idRetailer, { userProperties: { codiceUnivoco: codiceUnivoco } });
  }
  getOnboardingInfo() {
    return this.http.get(ONBOARDING_URL);
    // user properties
  }

  uploadDocuments(formData) {
    let url = `${UPLOAD_URL}`;
    return this.createObject(url, formData);
  }

  completeOnboarding(jsonToSend) {
    return this.createObject(ONBOARDING_URL, jsonToSend);
  }
  resetPasword(email: string) {
    return this.createObject(CUSTOM_PRINCIPAL_URL + 'resetPasswordRequest?username=' + email, null);
  }
}
