import {Hateoas} from './hateoas';
import {HrefLink} from '../interfaces/generic-links';
import {Answer} from './answer';

export class Trial extends Hateoas<TrialLinks> {
  id: number;
  state: string;
  email: string;
  bill: string;
  startTrial: string;
  cognome: string;
  nome: string;
  cardLastNumbers: number;
  birthdate: string;
  productCode: number;
  surveyAnswers: Answer[];
  productModel: string;
  endTrial: string;
  telefono: number;
  productImage: string;
  paidOnTimestamp: number;
}
export interface TrialLinks{
  self: HrefLink;
  customer: HrefLink;
  purchase: HrefLink;
  product: HrefLink;
  retailer: HrefLink;
}
export interface TrialGetList{
  purchases: Trial[];
}
