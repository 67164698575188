import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import * as moment from 'moment';

@Pipe({name: 'dateTimeFormatFilter'})
export class DateTimeFormatPipe implements PipeTransform {
  transform(date: any, format: string): any {
    if (date) {
      return moment(date).format(format);
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() {
  }

  getIdBySelfHref(selfHref): number {
    const index = selfHref.lastIndexOf('/');
    let id = '1';
    if (index != -1) {
      id = selfHref.substring(index + 1, selfHref.length);
    }
    return Number(id);
  }
  validateDate(control: AbstractControl) {
    const today = new Date();
    const data = control.value;
    let dataNascita = new Date(data);
    if (isNaN(dataNascita.valueOf()) && control.value.length === 10) {
      dataNascita = new Date(this.getReverseDate(data));
    }
    let age = today.getFullYear() - dataNascita.getFullYear();
    const m = today.getMonth() - dataNascita.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < dataNascita.getDate())) {
      age--;
    }
    if (age >= 18) {
      return null;
    } else {
      return {inValidAge: true};
    }
  }

  getReverseDate(date): string {
    const day = date.substr(0, 2);
    const month = date.substr(3, 2);
    const year = date.substr(6, 4);
    return year + '/' + month + '/' + day;
  }

  expirationDate(control: AbstractControl) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30);
    let data = control.value;
    let userDate = new Date(data);
    if (isNaN(userDate.valueOf()) && control.value.length === 10) {
      const day = data.substr(0, 2);
      const month = data.substr(3, 2);
      const year = data.substr(6, 4);
      data = year + '/' + month + '/' + day;
      userDate = new Date(data);
    }
    if (userDate.getFullYear() > expirationDate.getFullYear()) {
      return null;
    } else if (userDate.getMonth() >= expirationDate.getMonth() && userDate.getFullYear() === expirationDate.getFullYear() || isNaN(userDate.valueOf())) {
      if (userDate.getTime() >= expirationDate.getTime() || isNaN(userDate.valueOf())) {
        return null;
      } else {
        return {inValidAge: true};
      }
    } else {
      return {inValidAge: true};
    }
  }
}
